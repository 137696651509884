<template>
    <div>
        
    </div>
</template>

<script>

export default {
    name: "",
    components: {

    },
    data(){
        return {

        }
    },
    beforeMount(){

    },
    methods: {

    }
}
</script>